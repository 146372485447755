.navbarBottom{
    background-color: #000;
    height: 60px;

    .navBarBottomWrapper{
        display: flex;
        align-items: center;
        padding: 20px 30px;
        color: white;
        justify-content: space-between;
        @media screen and (max-width: 801px) {
            display: none;
        }

        .item{
            position: relative;
            display: flex;
            align-items: center;
            cursor: pointer;
            padding-bottom: 10px;
            // transition: 0.5s;


            &:hover{
                border-bottom: solid #fff;
            }

            span{
                font-size: 1.3rem;
                line-height: 1.375rem;
                font-weight: 700;
            }
        }

        .dropDownMenu{
            position: absolute;
            background-color: white;
            top: 40px;
            left: -95px;
            width: 1145px;
            height: 300px;
            padding: 20px;
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
            border-bottom: 2px solid black;
            z-index: 1;

            .dropDownMenuWrapper{
                display: flex;

                .left{
                    flex: 1.9;
                    display: flex;
                    flex-direction: column;

                    .item{
                        display: flex;
                        align-items: center;
                        color: #666;
                        padding-bottom: 20px;
                        transition: 0.5s;

                        &:hover{
                            color: #000;
                        }
                    }
                }
                .right{
                    flex: 10;
                    display: flex;
                    align-items: center;
                    flex-direction: column;

                    .rightTop{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 15px;

                        .item{
                            display: flex;
                            flex-direction: column;
                            width: 220px;
                            color: #666;
                            img{
                                position: relative;
                                width: 100%;
                                object-fit: cover;
                            }

                            .cat{
                                position: absolute;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                left: 15px;
                                top: 10px;
                                padding: 2px 10px;
                                background-color: #222;
                                color: white;
                                cursor: pointer;
                            }

                            .icon{
                                position: absolute;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                right: 15px;
                                top: 10px;
                                padding: 4px;
                                background-color: #222;
                                color: white;
                                cursor: pointer;
                            }
                            .title{
                                color: #666;
                                font-weight: bold;
                                transition: 0.5s;

                                &:hover{
                                    color: #000;
                                }
                            }
                        }
                    }

                    .rightBottom{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #666;
                        padding-top: 50px;
                        gap: 5px;
                        cursor: pointer;

                        .prew:hover{
                            color: #000;
                        }
                        .all:hover{
                            color: #000;
                        }
                        .next:hover{
                            color: #000;
                        }
                    }
                }
            }
        }


    }

    .dropdownwrapper{
        display: none;
        @media screen and (max-width: 801px) {
            display: flex;
            justify-content: end;
            align-items: end;
        }

        .bars{
            padding: 16px 30px 20px 20px;
            position: relative;
            width: 113px;
            cursor: pointer;

            .ul{
                position: absolute;
                background-color: #000;  
                z-index: 9999;
                width: 220px;
                left: -100%;
                padding-block: 20px;
                cursor: pointer;
                transition: 0.5s ;
                

                li,span{
                    color: #fff;
                    font-size: 1.3rem;
                    line-height: 1.375rem;
                    font-weight: 700;
                    padding-top: 20px;
                    cursor: pointer;
                    transition: 0.5s;

                    &:hover{
                     border-bottom: 1px solid white;
                    }
                    
                }
            }
        }
    }

}

.navbarBottom.fixed{
    position: sticky;
    top: 0;
    z-index: 99;
}