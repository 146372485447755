.audioVideo{
    padding: 30px 40px 20px 40px;
    background-color: black;

    .audioVideoWrapper{
        border-top: 2px solid white;
        display: flex;
        flex-direction: column;

        .title{
            color: white;
        }

        .icon{
            color: white;

            .left:hover{
                color: #ce0000;
            }
            .right:hover{
                color: #ce0000;
            }
        }
        .body{
            display: flex;
            gap: 30px;
            margin-bottom: 20px;
            @media screen and (max-width: 796px) {
                flex-direction: column;
            }
            .videoWrapper{
                flex: 8;
            }

            .listWrapper{
                flex: 4.5;

                .list{
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    justify-content: space-between;

                    .item{
                        display: flex;
                        align-items: center;
                        gap: 20px;
                        cursor: pointer;
                        @media screen and (max-width: 796px) {
                            margin-top: 10px;
                        }

                        .left{
                            img{
                                display: flex;
                                height: 100%;
                                width: 110px;
                                cursor: pointer;
                            }
                        }

                        .right{
                            display: flex;
                            flex-direction: column;

                            .nav{
                                list-style: none;
                                margin: 0;
                                padding: 0;
                                display: flex;
                                align-items: center;
                                gap: 15px;

                                li{
                                    color: #f4f4f4;
                                    cursor: pointer;
                                    transition: 0.5s;

                                    &:hover{
                                        color: white;
                                    }
                                }
                            }

                            h3{
                                display: flex;
                                font-size: 12px;
                                font-weight: 800;
                                color: white;
                            }
                        }

                        &:hover{
                            border-radius: 5px;
                            border-top: 1px solid rgba(0,0,0,0.3);
                            background-color: rgba(0,0,0,0.25);
                            box-shadow: inset 1px rgba(255,255,255,0.3);
                        }
                    }
                }
            }
        }
    }
}