.styleTwo{
    width: 100%;

    .top{
        position: relative;
        margin-bottom: 20px;
        width: 370px;
        height: 175px;
        overflow: hidden;
        @media screen and (max-width: 800px) {
            flex-direction: column;
            width: 100%;
            height: 220px;
        }
        @media screen and (max-width: 376px){
            margin-bottom: -25px;
        }

        img{
            width: 100%;
            object-fit: cover;
            cursor: pointer;
        }

        .cat{
            position: absolute;
            display: flex;
            top: 20px;
            left: 20px;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            font-weight: 500;
            color: white;
            text-transform: uppercase;
            background-color: #222;
            padding: 5px 10px;
            transition: 0.5s;
            cursor: pointer;

            &:hover{
                background-color: #000;
            }

        }

        
        .icon{
            position: absolute;
            display: flex;
            justify-content: center;
            top: 20px;
            right: 20px;
            font-size: 12px;
            font-weight: 500;
            color: white;
            text-transform: uppercase;
            background-color: #222;
            padding: 5px 10px;
            transition: 0.5s;
            cursor: pointer;

            &:hover{
                background-color: #000;
            }
        }

        .postInfo{
            position: absolute;
            bottom: 0;
            left: 0;
            display: flex;
            flex-direction: column;

            ul{
                list-style: none;
                padding: 0;
                margin: 0;
                display: flex;
                align-items: center;
                gap: 15px;
            }

            .nav{
                color: white;
                font-size: 14px;
                margin-left: 20px;
                transition: 0.5s;

                &:hover{
                    color: #ce0000;
                }
            }

            .nav li{
                font-weight: 600;
            }

            h3{
                padding: 0px 20px 20px 20px;
                font-size: 16px;
                color: white;
                font-weight: 800;
                width: 350px;
                cursor: pointer;
                transition: 0.5s;
                @media screen and (max-width: 404px) {
                    padding: 0px 20px 40px 20px;
                }
                @media screen and (max-width: 376px){
                    padding: 0px 0px 56px 8px;
                    width: 280px;
                }

                &:hover{
                    color: #ce0000;
                }
            }
        }
    }

    .bottom{
        .item{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 15px;
            gap: 20px;
            padding-top: 15px;
            border-top: 1px solid #ccc;

            .left{
                img{
                    width: 130px;
                    cursor: pointer;
                }
            }

            .right{
                display: flex;
                flex-direction: column;

                .nav{
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    gap: 15px;

                    li{
                        font-size: 13px;
                        color: #777;
                        transition: 0.5s;
                        cursor: pointer;

                        &:hover{
                            color: #ce0000;
                        }
                    }
                }

                h3{
                   text-align: start; 
                   font-size: 14px;
                   font-weight: 700;
                   color: #333;
                   transition: 0.5s;
                   cursor: pointer;

                   &:hover{
                       color: #ce0000;
                   }
                }
            }
        }
    }
}