.advertTop{
    padding: 0px 30px 0px 30px;

    .advertTopContainer{
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        .righ{
            @media screen and (max-width: 558px) {
                display: none;
            }
        }
    }
}