.widget{
    display: flex;
    flex-direction: column;
    gap: 60px;
    @media screen and (max-width: 939px) {
        display: none;
    }

    .top{
        padding: 30px 30px 15px;
        background-color: #f4f4f4;
        text-align: center;
        @media screen and (max-width: 978px) {
            display: none;
        }

        img{
            width: 100%;
            height: 100%;
            margin-bottom: 15px;
            cursor: pointer;
        }
    }

    .center{
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 0;
        border-top: 2px solid black;
        z-index: 0;

        h2{
            position: relative;
            margin: 0;
            color: black;
            font-size: 16px;
            font-weight: 700;
            line-height: 26px;
            text-transform: uppercase;
            z-index: 0;
        }

        .icon{
            color: #777;
            line-height: 26px;
            font-size: 18px;
        }
    }

    .bottom{
        margin-top: -55px;

        .nav{
            margin: 0;
            padding: 0;
            list-style: none;
            background-color: #f4f4f4;
            display: flex;
            flex-wrap: wrap;
            padding: 20px;
            align-items: center;
            justify-content: space-evenly;
            gap: 35px;
            
            
            li a{
                display: flex;
                align-items: center;
                flex-direction: column;
                cursor: pointer;
                color: #000;

            }

            li a > .icon{
                width: 60px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                font-size: 24px;
                margin-bottom: 5px;
                -webkit-transition: color 0.25s, background-color 0.25s ease-in-out;
                transition: color 0.25s, background-color 0.25s ease-in-out;

            }

            .facebook > a > span.icon{
                border: 1px solid #3b5998;
                color: #3b5998;

                &:hover{
                    color: white;
                    background-color: #3b5998;
                }
            }

            .x > a > span.icon{
                border: 1px solid #000;
                color: #000;

                &:hover{
                    color: white;
                    background-color: #000;
                }
            }

            .insta > a > span.icon{
                border: 1px solid #dc2743;
                color: #cc2366;

                &:hover{
                    color: white;
                    background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
                }
            }

            .youtube > a > span.icon{
                border: 1px solid #b00;
                color: #b00;

                &:hover{
                    color: white;
                    background: #b00 ;
                }
            }

            .medium > a > span.icon{
                border: 1px solid #000;
                color: #000;

                &:hover{
                    color: white;
                    background-color: #000;
                }
            }

            .quora > a > span.icon{
                border: 1px solid #b72831;
                color: #b72831;

                &:hover{
                    color: white;
                    background-color: #b72831;
                }
            }

        }
    }
    
}