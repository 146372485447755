.mainBodyTwo{
    display: flex;
    padding: 0px 30px;


    .mainBodyTwoWrapper{
        display: flex;
        gap: 25px;
        @media screen and (max-width: 1070px) {
            justify-content: space-between;
        }
        @media screen and (max-width: 800px) {
            flex-direction: column;
        }
        

        .styleOne1{
            flex: 4;
            width: 100%;
            @media screen and (max-width: 1070px) {
                flex: 0;
            }
        }

        .styleTwo2{
            flex: 4;
            width: 100%;
            @media screen and (max-width: 1070px) {
                flex: 0;
            }
        }

        .styleThree{
            flex: 4;
            width: 100%;
            @media screen and (max-width: 1070px) {
                display: none;
            }

            .widgets{
                display: flex;
                flex-direction: column;
                @media screen and (max-width: 1070px) {
                    display: none;
                }

                .widgetTitle{
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 12px 0px;
                    border-top: 2px solid #000;
                    z-index: 0;

                    h2{
                        position: relative;
                        margin: 0;
                        color: #000;
                        font-size: 16px;
                        line-height: 26px;
                        font-weight: 700;
                        text-transform: uppercase;
                        z-index: 0;
                    }

                    .icon{
                        color: #777;
                        font-size: 20px;
                        line-height: 26px;
                        cursor: pointer;
                    }
                }

                .list{
                    display: flex;
                    flex-direction: column;
                    background-color: #f4f4f4;
                    padding: 15px;
                    margin-bottom: 60px;

                    .top{
                        display: flex;

                        ul{
                            list-style: none;
                            margin: 0;
                            padding: 0;
                            width: 100%;
                            gap: 20px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                    

                            li{
                                color: #777;
                                padding: 5px 0;
                                transition: 0.7s;
                            }

                            li a{
                                font-weight: 700;
                                cursor: pointer;
                            }

                            li.active{
                                color: white;
                                background-color: #000;
                                padding: 5px 6px;
                            }
                        }
                    }

                    .bottom{
                        .item{
                            display: flex;
                            align-items: center;
                            margin-top: 15px;
                            gap: 15px;
                            padding: 15px ;
                            border-top: 1px solid #ccc;
                             @media screen and (max-width: 1173px) {
                                flex-direction: column;
                            }

                            .left{
                                width: 110px;
                                cursor: pointer;
                            }

                            .right{
                                display: flex;
                                flex-direction: column;

                                .nav{
                                    list-style: none;
                                    margin: 0;
                                    padding: 0;
                                    display: flex;
                                    align-items: center;
                                    gap: 15px;


                                    li{
                                        color: #777;
                                        transition: 0.5s;
                                        cursor: pointer;

                                        &:hover{
                                            color: #ce0000;
                                        }
                                    }
                                }

                                h3{
                                    font-weight: 700;
                                    color: #333;
                                    transition: 0.5s;
                                    cursor: pointer;

                                    &:hover{
                                        color: #ce0000;
                                    }

                                }
                            }
                        }
                    }

                }

                .adWidget{
                    padding: 30px 30px 15px;
                    background-color: #f4f4f4;
                    text-align: center;

                    img{
                        width: 100%;
                        height: 100%;
                        margin-bottom: 15px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}