.mainBodyFour{
    display: flex;
    margin: 0px 30px;
    margin-top: 30px;

    .mainBodyFourWrapper{
        display: flex;
        gap: 25px;
        @media screen and (max-width: 1070px) {
            justify-content: space-between;
        }
        @media screen and (max-width: 800px) {
            flex-direction: column;
        }

        .styleOne1{
            flex: 4;
            @media screen and (max-width: 1070px) {
                flex: 0;
            }
        }

        .styleTwo2{
            flex: 4;
            @media screen and (max-width: 1070px) {
                flex: 0;
            }
        }

        .styleThree3{
            flex: 4;
            @media screen and (max-width: 1070px) {
                display: none;
            }
        }
    }

}