.footer
{
    background: #111;
}
.wrapper
{
    margin: 0 auto;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: left;
    flex-wrap: wrap;
    gap: 15px;
    padding-top: 20px;
}
ul
{
    list-style: none;
    li{
        color: #FEFEFF99;
        &:hover{
            color: white;
        }
    }
    .list1{
        padding: 0;
        padding-bottom: 15px;
    }
}
footer li
{
    padding: 2px 0px;
}
.info
{
    font-size: 21px;
    color: white;
    font-weight: 600;
    padding-bottom: 10px;
}
footer li
{
    color: #FEFEFF99;
}
.wrapper a
{
    color: #FEFEFF99;
    text-decoration: none;
}
.wrapper a:hover
{
    color: white;
}
footer i
{
    font-size: 25px;
    color: #FEFEFF99;
    border-radius: 30px;
    border: 2px solid #FEFEFF99;
    cursor: pointer;
}
.icons
{
    display: flex;
    column-gap: 10px;

    i{
        border-radius: 50%;
    }
}
.icons i:hover
{
    background-color: white;
    color: black;
}
.list1
{
    // padding: 20px 0px;
}
.wrapper2{
    border-top: 1px solid #FEFEFF99;
    
}
.wrapper2 p
{
    padding: 6px 30px;
    color: #FEFEFF99;
    margin-bottom: 0;
    font-size: 12px;
}
.logo-1>img {
    width: 180px;
    // height: 55px;
    cursor: pointer;
}