.headingTitle{
    border-top: 2px solid #222;

    .heading{
        display: flex;
        align-items: center ;
        padding: 15px 0px;
        justify-content: space-between;

        .title{
            font-size: 1.3rem;
            line-height: 1.375rem;
            font-weight: 700;
            color: #222;
            text-transform: uppercase;
        }

        .icon{
            display: flex;
            align-items: center;
            color: #777;
            gap: 2px;
            cursor: pointer;

            .left:hover{
                color: #000;
            }
            .right:hover{
                color: #000;
            }
        }
    }
}