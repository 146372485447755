.mainContent{
    display: flex;
    height: 65vh;
    margin: -40px 30px 0px 30px;
    padding-bottom: 30px;
    gap: 10px;
    @media screen and (max-width: 850px) {
        height: 50vh;
    }
    @media screen and (max-width: 796px) {
        flex-direction: column;
    }
    @media screen and (max-width: 796px) {
        flex-direction: column;
    }

    .col{
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 10px;
        overflow: hidden;
        @media screen and (max-width: 796px) {
            overflow: visible;
            align-items: center;
        }

        .row{
            position: relative;
            flex: 1;
            display: flex;
            gap: 10px;
            @media screen and (max-width: 796px) {
                justify-content: center;
                flex-direction: column;
            }

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                @media screen and (max-width: 796px) {
                    width: 540px;
                    height: 200px;
                }
                @media screen and (max-width: 583px) {
                    width: 500px;
                    height: 200px;
                }
                @media screen and (max-width: 517px) {
                    width: 450px;
                    height: 200px;
                }
                @media screen and (max-width: 567px) {
                    width: 400px;
                    height: 200px;
                }
                @media screen and (max-width: 414px) {
                    width: 349px;
                    height: 200px;
                }
                @media screen and (max-width: 349px) {
                    width: 296px;
                    height: 200px;
                }
            }

            .cat{
                position: absolute;
                top: 20px;
                left: 20px;
                display: flex;
                background-color: #111;
                color: white;
                padding: 5px 10px;
                font-size:  14px;
                font-weight: 500;
                text-transform: uppercase;
                align-items: center;
                justify-content: center;
            }
        }

        .icon{
            position: absolute;
            top: 20px;
            right: 20px;
            display: flex;
            background-color: #111;
            color: white;
            padding: 5px 10px;
            font-size:  12px;
            font-weight: 500;
            text-transform: uppercase;
            align-items: center;
            justify-content: center;
            @media screen and (max-width: 796px) {
                display: none;
            }
        }
    }

    .postInfo{
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: column;

        ul{
            list-style: none;
            display: flex;
            align-items: center;
            gap: 15px;
        }

        .nav{
            color: white;
            font-size: 14px;
            font-weight: 600;
            margin-left: -15px;
            transition: 0.5s;

            &:hover{
                color: #ce0000;
                cursor: pointer;
            }
        }

        .nav li{
            font-weight: 600;
        }

        h3{
            padding: 10px 20px 20px 20px;
            width: 550px;
            font-size: 18px;
            margin-left: 5px;
            font-weight: 700;
            color: white;
            transition: 0.5s;
            @media screen and (max-width: 592px) {
                width: 500px;
            }
            @media screen and (max-width: 517px) {
                width: 400px;
            }
            @media screen and (max-width: 414px) {
                width: 300px;
            }

            &:hover{
                color: #ce0000;
                cursor: pointer;
            }
        }
    }
}