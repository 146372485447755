.hotLinks{
    padding: 20px 30px 30px 30px;
    margin: 0 auto;
    .hotLinksWrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 40px;
        margin-bottom: 20px;
        @media screen and (max-width: 400px) {
            gap: 25px;
            font-size: 12px;
        }

        .item{
            display: flex;
            align-items: center;

            a{
                display: flex;
                align-items: center;
                flex-direction: column;
                gap: 5px;
                color: #999;
                transition: 0.5s;

                &:hover{
                    color: black;
                    cursor: pointer;
                }

                span{
                   font-size: 16px; 
                   font-weight: 900;
                   @media screen and (max-width: 400px) {
                    font-size: 12px;
                }
                }
            }
        }
    }
    .new--ticker{
        display: inline-block ;
        margin: 30px 0px 50px 0px ;

        .container{
            .title{
                padding: 11px 30px 13px;
                background-color: #000;
                color: white;

                h2{
                    display: inline-block;
                    margin: 0px 10px 0px 0px;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 26px;
                }

                span{
                    color: #ccc;
                    line-height: 24px;
                }
            }
        }
    }
}