.navbarTop{
    background-color: #111;
    height: 50px;
    @media screen and (max-width: 768px) {
        height: 80px;
    }

    .navbarContainer{
        padding: 12.5px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media screen and (max-width: 768px) {
            flex-direction: column;
            gap: 18px;
        }
        @media screen and (max-width: 400px) {
            flex-direction: column;
            gap: 10px;
            font-size: 7;
        }

        .left{
            display: flex;
            align-items: center;
            gap: 15px;
            justify-content: center;
            @media screen and (max-width: 400px) {
                gap: 8px;
                font-size: 7px;
            }

            .item{
                display: flex;
                align-items: center;
                color: #d9d9d9;
                cursor: pointer;
                transition: 0.5s;

                &:hover{
                    color: white;
                }
                span{
                    font-size: 14px;
                    font-weight: 500;
                    @media screen and (max-width: 359px) {
                        font-size: 12px;
                    }
                }
            }
        }

        .right{
            position: relative;
            display: flex;
            align-items: center;
            gap: 15px;
            justify-content: center;
            @media screen and (max-width: 400px) {
                gap: 14px;
                font-size: 7px;
            }
        }
        
        .item{
            display: flex;
            align-items: center;
            color: #d9d9d9;
            cursor: pointer;
            transition: 0.5s;

            &:hover{
                color: white;
            }
            span{
                font-size: 15px;
                font-weight: 500;
            }


        }
        .google{
            margin-right: 144px;
        }

        .icons{
            display: flex;
            color: #d9d9d9;
            gap: 15px;
            cursor: pointer;
            font-size: 15px;
            @media screen and (max-width: 362px) {
                gap: 7px;
                font-size: 12px;
            }
            a{
                color: #d9d9d9;
            }
        }
    }
}