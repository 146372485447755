.viewWrapper{
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 5px 10px rgba(0,0,0.1);


    .content{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 1rem;

        .contentWrapper{
            display: flex;
            flex-direction: column;
            align-items: left;
            justify-content: center;
            padding: 20px 20px;
            width: 80%;
            @media screen and (max-width: 575.98px){
                width: 100%;
            }
            @media screen and (max-width: 375.98px){
                padding: 20px 0px;
            }

            h1{
                font-weight: 800;
                font-size: 2.75rem;
                line-height: 3.625rem;
                padding-bottom: 20px;
                padding-block: 20px;
                @media screen and (max-width: 375.98px){
                    font-size: 1.8rem;
                    line-height: 2.2rem;
                }
                
            }

            .img{
                width: 100%;
                img{
                    width: 100%;
                    object-fit: cover;
                    height: auto;
                }
            }
            .des{
                padding-block: 15px;
                h2{
                    font-weight: 700;
                    font-size: 22px;
                    padding-top: 4px;
                    @media screen and (max-width: 375.98px){
                        font-size: 18px;
                    }
                }
                p{  
                    padding-bottom: 15px;
                    padding-top: 4px;
                    font-size: 1.125rem;
                    line-height: 1.875rem;
                }
                .img1,.img2{
                    width: 50%;
                    @media screen and (max-width: 575.98px){
                        width: 100%;
                    }
                    img{
                        width: 100%;
                        object-fit: cover;
                        height: auto;
                    }
                }
            }
        }
    }
}
.goog-te-gadget .goog-te-combo {
    margin: 4px 0;
    color: #777;
    background-color: #000;
}
.goog-te-gadget {
    font-family: arial;
    font-size: 11px;
    color: #666;
    white-space: nowrap;
    position: absolute;
    top: 0;
}
.google_element{
    margin-left: 500px;
    position: relative;
    
}