.styleThree{
    .styleThreeWrapper{
        .widget3{
            margin-bottom: 30px;

            .pollWidget{
                background-color: #f4f4f4;
                padding: 20px;

                .title{
                    h3{
                        color: #333;
                        font-size: 16px;
                        font-weight: 700;
                        padding-bottom: 15px;
                    }
                }

                .options{
                    border-top: 1px solid #ccc;

                    form{
                        display: flex;
                        flex-direction: column;
                        padding-top: 15px;

                        .checkBox, .radio{
                            position: relative;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            gap: 20px;

                            label{
                                display: flex;
                                align-items: center;
                                gap: 10px;
                                color: #555;
                                padding: 10px 0px;

                                span{
                                    cursor: pointer;
                                }
                            }

                            p{
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                color: white;
                                position: absolute;
                                right: 0;
                                width: 120px;
                                height: 12px;
                                background-color: white;
                                margin-right: 35px;
                                border: 1px solid #ccc;


                                span{
                                    width: 100%;
                                    height: 100%;
                                    background-color: #ce0000;
                                }
                            }

                            .perc{
                                color: #555;

                            }

                        }

                        button{
                            margin-top: 20px;
                            background-color: black;
                            color: white;
                            padding: 3px 15px;
                            max-width:fit-content ;
                            border: none;
                            font-weight: 800;
                            cursor: pointer;
                        }
                    }
                }
            }

        }
    }
}